<template>
  <div class="fancy-feature-thirteen pt-120 md-pt-80" id="feature">
    <div class="container">
      <div class="businessFutureReadyContainer">
        <img
          loading="lazy"
          width="127"
          height="126"
          class="floatingImage f-1"
          src="../../assets/images/assets/diamondIcon.png"
          alt="Diamond icon"
        />
        <img
          loading="lazy"
          width="66"
          height="85"
          class="floatingImage f-2"
          src="../../assets/images/assets/toyIcon.png"
          alt="Diamond icon"
        />
        <img
          loading="lazy"
          width="99"
          height="99"
          class="floatingImage f-3"
          src="../../assets/images/assets/diamondIcon1.png"
          alt="Diamond icon"
        />
        <h3 class="textc">PRODUCT DESIGN & STRATEGY</h3>
        <p class="textc">
          We follow end-to-end agile product development approach to Deliver
          robust & scalable Digital Products.
        </p>
        <div class="flexBusinessContainer">
          <article class="imgContainer">
            <img
              loading="lazy"
              src="../../assets/images/assets/manFloating.png"
              alt="Man floating"
            />
          </article>
          <article class="pointsBusinessFutureReady">
            <h5 style="text-align-center">Our services</h5>
            <ul>
              <li>Web Development</li>
              <li>Mobile Development</li>
              <li>Custom Software Development</li>
              <li>Delivery solutions for React, React Native</li>
            </ul>
          </article>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="fancy-feature-thirteen pt-120 md-pt-80" id="feature">
    <div class="container">
      <h3 class="textc text-align-center">Our Services</h3>
      <div class="list-services row pt-1">
        <div class="col-xl-4 col-lg-12 col-md-12">
          <p>Web Development</p>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
          <p>Mobile Development</p>
        </div>
        <div class="col-xl-4 col-lg-12 col-md-12">
          <p>Custom Software Development</p>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "FeatureTwo",
};
</script>
