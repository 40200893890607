<template>
  <div class="fancy-portfolio-one pt-180 md-pt-100" id="product">
    <div class="title-style-six d-grid align-items-center" style="gap: 10px">
      <h4 class="smallHeading text-align-center w-100">PORTFOLIO</h4>
      <h2 class="text-align-center w-100">
        Our Digital <span>Masterpieces</span>
      </h2>
    </div>
    <div class="portfolio_slider_three pt-100 md-pt-70">
      <swiper
        class=""
        ref="mySwiper"
        :modules="modules"
        :loop="true"
        :navigation="{nextEl: '.next_c', prevEl: '.prev_c'}"
        :breakpoints="breakpoints">
        <swiper-slide
          v-for="testimonial in testimonialData"
          :key="testimonial.id">
          <div class="item">
            <div class="img-meta">
              <img :src="testimonial.img" alt="" />
            </div>
            <div class="content-meta">
              <h3 class="portfolioTitle">{{ testimonial.projectName }}</h3>
              <p class="title-meta">{{ testimonial.title }}</p>
              <ul class="list-item-product">
                <li>Team size: {{ testimonial.size_member }}</li>
                <li>Project size: {{ testimonial.project_size }} months</li>
              </ul>
              <ul class="list-techology">
                <li :key="tech" v-for="tech in testimonial.techs">
                  {{ tech }}
                </li>
              </ul>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import {Navigation} from "swiper";

export default {
  name: "FancyPortfolio",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      testimonialData: [
        {
          id: 1,
          img: require(`@/assets/images/assets/portfolioImage-flyin.png`),
          title: `Mirron is a free online matching platform for beauty stylists and clients in Africa. You can find a stylist best-fit your beauty needs closest to you on demand.`,
          size_member: "10+",
          project_size: 36,
          projectName: "Mirron",
          techs: [
            "React Native",
            "Redux",
            "iOS",
            "Android",
            "NodeJs",
            "MongoDB",
          ],
        },
        {
          id: 2,
          img: require(`@/assets/images/assets/portfolioImage-relovo.png`),
          title: `No Code allows you to easily create apps with a database such as Excel by arranging elements. You can create apps for Iphone, Android or website at the same time without coding skills.
`,
          size_member: "29",
          project_size: 36,
          projectName: "No Code",
          techs: [
            "ReactJS",
            "Redux",
            "Ant design",
            "Zappier",
            "PostgreSQL",
            "Firebase",
            "React Native",
            "Stripe",
            "NodeJS",
            "FeathersJS",
            "AWS",
            "CubeJS",
          ],
        },
        {
          id: 3,
          img: require(`@/assets/images/assets/portfolioImage-utrack.png`),
          title: `With Ksafe, You can start earning points that can be redeemed for rewards as soon as you put the keys in the ignition.`,
          size_member: "8",
          project_size: 12,
          projectName: "K-safe",
          techs: ["React native", "Redux", "Kotlin", "Firebase", "MongoDB"],
        },
        {
          id: 4,
          img: require(`@/assets/images/assets/welltab.png`),
          title: `In 2020, the COVID-19 outbreak prevented people to contact their family, friends, colleagues, etc. in person. This application easily helps to communicate with their family members.`,
          size_member: "11",
          project_size: 20,
          projectName: "Welltab",
          techs: ["React native", "Redux", "Firebase", "MongoDB", "WebRTC"],
        },
      ],
      breakpoints: {
        0: {slidesPerView: 1},
        480: {slidesPerView: 1},
        576: {slidesPerView: 1},
        900: {slidesPerView: 2},
        1340: {slidesPerView: 3},
      },
    };
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>
