<template>
  <div class="client-feedback-slider-five pt-130 md-pt-80 mt-100" id="feedback">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-10 col-md-9 m-auto">
          <img
              src="../../assets/images/icon/84.svg"
              alt=""
              class="icon m-auto"
          />
          <div class="title-style-six text-center mt-25">
            <h2>
              Fantastic is one of the top
              <span>ReactJS</span> & <span>React Native</span> development
              Companies in Vietnam
            </h2>
          </div>
          <!-- /.title-style-six -->
        </div>
      </div>

      <div class="row">
        <div class="col-xl-7 col-lg-10 m-auto">
          <div class="clientSliderFive mt-80 md-mt-50">
            <swiper
                ref="mySwiper"
                :modules="modules"
            >
              <swiper-slide
                  :isPrev="false"
                  :isNext="false"
                  v-for="testimonial in testimonialData"
                  :key="testimonial.id"
              >
                <div class="item">
                  <p>
                    {{ testimonial.desc }}
                  </p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <img
              src="../../assets/images/shape/156.svg"
              alt=""
              class="shapes shape-one"
          />
          <img
              src="../../assets/images/shape/157.svg"
              alt=""
              class="shapes shape-two"
          />
          <img
              src="../../assets/images/shape/158.svg"
              alt=""
              class="shapes shape-three"
          />
        </div>
      </div>
    </div>
    <div class="circle-area">
      <img src="../../assets/images/shape/155.svg" alt="" class="main-img" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
export default {
  name: "ClientFeedback",
  data() {
    return {
      testimonialData: [
        {
          id: 1,
          desc: `
              We began as a family business in 2016 with the sole purpose of
              addressing how businesses would evolve and grow with technology.
              With dedicated team of over 150 high quality React & React Native
              engineers, we always deliver projects that need to be agile and
              quickly deliver MvPs with business sense. Our primary clients
              would be technology enthusiast that would not be frightened of new
              and modern technology. Whether you are a startup or a web agency,
              we work as an extension to your team, bringing our resources and
              expertise to your organization.
          `,
        }]
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>
