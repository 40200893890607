<template>
  <div
    class="modal fade modal-contact-popup-one"
    id="contactModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contactModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <main class="main-body modal-content clearfix">
        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <!-- <script src="https://www.cognitoforms.com/f/seamless.js" data-key="CsirwcrqlkWQcBqL9s-2pA" data-form="1"></script> -->
          <img src="../../../assets/images/icon/close.svg" alt="" />
        </button>
        <div class="left-side">
          <div class="d-flex flex-column justify-content-between h-100">
            <div class="row">
              <div class="col-xl-10 col-lg-8 m-auto">
                <blockquote>
                  “I never dreamed about success. I worked for it.”
                </blockquote>
                <span class="bio">—Estée Lauder</span>
              </div>
            </div>
            <img
              src="../../../assets/images/assets/ils_18.svg"
              alt=""
              class="illustration mt-auto"
            />
          </div>
        </div>
        <!-- /.left-side -->
        <div class="right-side">
          <h2 class="form-title">Contact us</h2>
          <div class="form-input-cog" ref="formInput" />
        </div>
        <!-- /.right-side -->
      </main>
      <!-- /.main-body -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactModal",
  mounted() {
    let script = document.createElement("script");
    script.src = "https://www.cognitoforms.com/f/seamless.js";
    script.setAttribute("data-key", "CsirwcrqlkWQcBqL9s-2pA")
    script.setAttribute("data-form", "1")
    this.$refs.formInput.append(script);
  },
  methods: {
    onSubmit() {
      console.log("Submitted");
    },
  },
};
</script>