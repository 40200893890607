<template>
  <div class="fancy-feature-twelve pt-250 md-pt-50" id="about">
    <div class="bg-wrapper md-pt-60">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-10 col-md-8 m-auto">
            <div class="title-style-six text-center">
              <h2>Check why you <span>Should</span> Choose us</h2>
            </div>
            <!-- /.title-style-six -->
          </div>
        </div>

        <div
          class="row justify-content-center pt-50 md-pt-20"
          style="align-items: stretch">
          <div
            v-for="item in featureData"
            :key="item.id"
            class="col-lg-4 col-md-6">
            <div
              class="block-style-seventeen mt-40"
              style="height: calc(100% - 40px)">
              <div
                class="icon d-flex align-items-center justify-content-center">
                <img :src="item.icon" alt="" />
              </div>
              <div class="static-text">
                <h3>{{ item.title }}</h3>
                <p>{{ item.subtitle }}</p>
              </div>
              <div class="hover-text" style="padding-right: 32px">
                {{ item.hover_text }}
              </div>
            </div>
            <!-- /.block-style-seventeen -->
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
    <img
      src="../../assets/images/shape/151.svg"
      alt=""
      class="shapes shape-one" />
  </div>
</template>

<script>
export default {
  name: "FeatureArea",
  data() {
    return {
      featureData: [
        {
          id: 1,
          icon: require(`@/assets/images/icon/78.svg`),
          title: "ReactJS & React Native Specilization",
          subtitle:
            "We have a dedicated team of over 200 high-quality React, React native and NodeJS engineers, many of them have over 7 years of experience. We always deliver projects that need to be agile and quickly deliver MvPs with business sense",
          hover_text:
            "We have a dedicated team of over 200 high-quality React, React native and NodeJS engineers, many of them have over 7 years of experience. We always deliver projects that need to be agile and quickly deliver MvPs with business sense",
        },
        {
          id: 2,
          icon: require(`@/assets/images/icon/79.svg`),
          title: "Sameless Communication",
          subtitle:
            "With our engineers' verbal English or Japanese skills, we can understand your request and turn it into real product. Time gap isn't a problem since we give 24/7 response. Also, you can check on project's progress everyday.",
          hover_text:
            "With our engineers' verbal English or Japanese skills, we can understand your request and turn it into real product. Time gap isn't a problem since we give 24/7 response. Also, you can check on project's progress everyday.",
        },
        {
          id: 3,
          icon: require(`@/assets/images/icon/80.svg`),
          title: "Client is our Teammate",
          subtitle:
            "We always consider you as our teammate, always support in any case when unexpected problems occur. Therefore, the outcome meets 100% project requirements.",
          hover_text:
            "We always consider you as our teammate, always support in any case when unexpected problems occur. Therefore, the outcome meets 100% project requirements.",
        },
      ],
    };
  },
};
</script>
