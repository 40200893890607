<template>
  <div>
    <div class="hero-banner-six">
      <div class="illustration-content">
        <img
          src="../../assets/images/shape/144.svg"
          alt=""
          class="main-img ms-auto"
        />
        <div class="screen-one-wrapper">
          <img
            src="../../assets/images/shape/reactjs.jpeg"
            alt=""
            class="screen-one"
          />
        </div>
        <div class="screen-two">
          <div class="screen-two-backdrop" />
          <img
            src="../../assets/images/shape/mobile.jpeg"
            alt=""
            class="ms-auto screen-one-img"
          />
        </div>
        <img
          src="../../assets/images/shape/145.svg"
          alt=""
          class="shapes shape-one"
        />
        <img
          src="../../assets/images/shape/146.svg"
          alt=""
          class="shapes shape-two"
        />
        <img
          src="../../assets/images/shape/147.svg"
          alt=""
          class="shapes shape-three"
        />
        <img
          src="../../assets/images/shape/148.svg"
          alt=""
          class="shapes shape-four"
        />
        <img
          src="../../assets/images/shape/149.svg"
          alt=""
          class="shapes shape-five"
        />
        <img
          src="../../assets/images/shape/150.png"
          alt=""
          class="shapes shape-six"
        />
        <div class="shapes oval-one"></div>
        <div class="shapes oval-two"></div>
        <div class="shapes oval-three"></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <h1 class="font-montserrat hero-heading fs-10">
              Delivering <span>FANTASTIC</span> services and transform your
              vision into real reality.
            </h1>
            <p class="hero-sub-heading">
              Fantastic always remain on the forefront of innovation,
              implementing solutions to suit even the most unique needs.
            </p>
          </div>
        </div>
        <div class="hero-button-request-a-quote">
          <a href="#" data-bs-toggle="modal" data-bs-target="#contactModal"
            >Request a Quote</a
          >
        </div>
      </div>
    </div>
    <div class="container">
      <div class="carousel-wrapper">
        <carousel
          :items-to-show="5"
          :wrap-around="true"
          :autoplay="3000"
          :breakpoints="breakpoints"
        >
          <slide :key="0">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/nextjs.svg"
              alt=""
            >
          </slide>
          <slide :key="1">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/react-2.svg"
              alt=""
            >
          </slide>
          <slide :key="2">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/react-native-1.svg"
              alt=""
            >
          </slide>
          <slide :key="3">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/nodejs-1.svg"
              alt=""
            />
          </slide>
          <slide :key="4">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/redux-saga.svg"
              alt=""
            >
          </slide>
          <slide :key="5">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/firebase-1.svg"
              alt=""
            >
          </slide>
          <slide :key="6">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/express-109.svg"
              alt=""
            >
          </slide>
          <slide :key="7">
            <img
              class="image-carousel"
              src="../../assets/images/assets/socket-io.svg"
              alt=""
            >
          </slide>
          <slide :key="8">
            <img
              class="image-carousel"
              src="../../assets/images/assets/figma-5.svg"
              alt=""
            >
          </slide>
          <slide :key="8">
            <img
              draggable="false"
              class="image-carousel"
              src="../../assets/images/assets/jira-1.svg"
              alt=""
            >
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "HeroArea",
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    breakpoints: {
      // 700px and up
      100: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      500: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 6,
        snapAlign: "start",
      },
    },
  }),
};
</script>
