<template>
  <div class="fancy-feature-fourteen pt-250 pt-80 md-pt-80">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-7 col-md-8">
          <div class="title-style-six">
            <h2>
              Years Of Experience <br />
              That Helps <span>Accelerate</span> Your Business.
            </h2>
            <p class="text-xs pe-5 me-5">
              We run the extra mile to deliver the best we can.
            </p>
          </div>
          <!-- /.title-style-six -->
        </div>

        <div class="col-lg-5 ms-auto">
          <div class="right-side md-mt-60">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="logo-meta lg d-flex align-items-center justify-content-center js-tilt"
                >
                  <div style="text-align: center">
                    <div id="counter" class="counter-value" data-count="500">
                      7+
                    </div>
                    <div class="description-meta">years of experience</div>
                  </div>
                </div>
                <div
                  class="logo-meta d-flex align-items-center justify-content-center js-tilt"
                >
                  <div style="text-align: center">
                    <div id="counter" class="counter-value" data-count="500">
                      150+
                    </div>
                    <div class="description-meta">talent developers</div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="logo-meta mt-40 d-flex align-items-center justify-content-center js-tilt"
                >
                  <div style="text-align: center">
                    <div id="counter" class="counter-value" data-count="500">
                      100+
                    </div>
                    <div class="description-meta">projects completed</div>
                  </div>
                </div>
                <div
                  class="logo-meta lg d-flex align-items-center justify-content-center js-tilt"
                >
                  <div style="text-align: center">
                    <div id="counter" class="counter-value" data-count="500">
                      100+
                    </div>
                    <div class="description-meta">client served</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.right-side -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureThree",
};
</script>