<template>
  <ProductLandingDarkMain/>
</template>

<script>
import ProductLandingDarkMain from '../components/product-landing-dark';

export default {
  name:'ProductLandingDark',
  components:{
    ProductLandingDarkMain,
  }
}
</script>