<template>
  <div class="fancy-feature-thirtyOne pt-120 md-pt-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 m-auto">
          <div class="title-style-four text-center pb-50 md-pb-20">
            <h2>Our Services</h2>
          </div>
        </div>
      </div>
      <div class="services-fancy pt-50">
        <div class="row">
          <div
            v-for="service in servicesData"
            :key="service.id"
            class="col-xl-4 col-lg-12  m-auto">
            <div class="img-meta">
              <h4>{{ service.projectName }}</h4>
              <!-- <img :src="service.img" alt="" /> -->
            </div>
            <div class="content-service">
              <ul class="list-item-product">
                <li  v-for="tech in service.techs" :key="tech">{{ tech }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServicesArea",
  data() {
    return {
      servicesData: [
        {
          id: 1,
          img: require(`@/assets/images/assets/w-development.jpeg`),
          projectName: "Web Development",
          techs: [
            "Custom website development",
            "Content Management System (CMS)",
            "Web based business & workflow",
            "UI/UX Design",
            "Complex backend systems with API intergration",
          ],
        },
        {
          id: 2,
          img: require(`@/assets/images/assets/m-development.jpeg`),
          projectName: "Mobile Development",
          techs: [
            "Cross-platform app development",
            "Mobile app development with React Native",
            "Payment intergration",
            "ZappMobile app testing",
            "Proof of concept",
          ],
        },
        {
          id: 3,
          img: require(`@/assets/images/assets/software.jpeg`),
          projectName: "Custom Software Development",
          techs: [
            "CRM software",
            "ERP system development",
            "E-learning software",
            "Live-streaming software",
            "Others on demand",
          ],
        },
      ],
      breakpoints: {
        0: {slidesPerView: 1},
        576: {slidesPerView: 1},
        991: {slidesPerView: 1},
        1340: {slidesPerView: 3},
      },
    };
  },
};
</script>
