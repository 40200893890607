<template>
  <footer class="theme-footer-six">
    <div class="inner-container">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4">
            <div class="logo">
              <router-link to="/">
                <img src="../../../assets/images/logo/Fantastic.png" alt="" />
                <span>Fantastic</span>
              </router-link>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="title">Find us on Social Media</div>
            <ul class="d-flex justify-content-center social-icon">
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/wendy-nguyen-b9171a243/"
                  ><i class="fa fa-linkedin" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a target="_blank" href="https://api.whatsapp.com/send?phone=+84976291434"><i class="fa fa-whatsapp"></i></a>
              </li>
            </ul>
          </div>
          <div class="col-lg-4">
            <div class="title">We’r always happy to help.</div>
            <div class="text-center">
              <a href="mailto:info@fantasticvn.com" class="email"
                >info@fantasticvn.com</a
              >
            </div>
            <div class="text-center pt-10">
              <a href="tel:+84976291434" class="email">+84976291434</a>
            </div>
            <div
              class="text-center pt-10"
              style="font-size: 16px; color: rgba(255, 255, 255, 0.8)">
              <p>
                CIT Building, No. 6 Alley 15, Duy Tan Street, Dich Vong Hau Ward, Cau Giay District, Hanoi City
              </p>
            </div>
          </div>
        </div>
        <p class="copyright">Copyright @2023 Fantastic inc.</p>
      </div>
      <img
        src="../../../assets/images/shape/167.png"
        alt=""
        class="shapes shape-one" />
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSix",
};
</script>