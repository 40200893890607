import {createRouter, createWebHistory} from 'vue-router'
import ProductLandingDark from '../pages/product-landing-dark';

const routes = [
    {
        path: '/',
        name: 'ProductLandingDark',
        component: ProductLandingDark,
        meta: {
            title: 'Fantastic React and React Native development',
        },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
