<template>
  <div
    class="main-page-wrapper font-gordita dark-style lg-container overflow-hidden">
    <HeaderSix :dark="true" />
    <HeroArea />
    <ClientFeedback />
    <Features />
    <FeatureTwo />
    <ServicesArea />
    <FeaturesThree />
    <FancyPortfolio />
    <Pricing />
    <div class="overflow-hidden">
      <FooterSix />
    </div>
  </div>
</template>

<script>
import HeaderSix from "../common/Headers/HeaderFive.vue";
import HeroArea from "./HeroArea.vue";
import Features from "./Features.vue";
import FeatureTwo from "./Feature-2.vue";
import FeaturesThree from "./Feature-3.vue";
import FancyPortfolio from "./Fancy-portfolio.vue";
import Pricing from "./Pricing.vue";
import FooterSix from "../common/Footers/FooterSix.vue";
import ServicesArea from "../services-v2/services.vue";
import ClientFeedback from "./Client-feedback";

export default {
  name: "ProductLandingDarkMain",
  components: {
    HeaderSix,
    ClientFeedback,
    HeroArea,
    Features,
    FeatureTwo,
    FeaturesThree,
    FancyPortfolio,
    Pricing,
    FooterSix,
    ServicesArea,
  },
};
</script>
